import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { addUserAnswer, closeTest } from '../graphql/mutations';
import { TestHeader, ButtonWithArrow } from '../ui-components';
import QCMAnswerWrapper from '../custom-components/QCMAnswerWrapper'
import QuestionImageWrapper from '../custom-components/QuestionImageWrapper';
import TestMenuWrapper from '../custom-components/TestMenuWrapper';
import ProgressBar from './ProgressBar';
import Spinner from './Spinner';
import FavoriteSelector from '../custom-components/FavoriteSelector'; // Import the FavoriteSelector component

export function TestInstance({ test, topicId }) {

  const { user } = useAuthenticator((context) => [context.user]);
  const userId = user.username;

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isCorrecting, setIsCorrecting] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState([]); // List of answered questions
  const [isLoading, setIsLoading] = useState(false);
  const [addAnswerPromise, setAddAnswerPromise] = useState(null);
  const [closeTestPromise, setCloseTestPromise] = useState(null);
  const navigate = useNavigate();
  
  // Register the length of unanswered questions in a prop and don't change it afterwards
  // Only if we received test
  const unansweredQuestionsLength = test ? test.testQuestions.items.filter(item => !item.userAnswer && !answeredQuestions.includes(item.id)).length : 0;

  useEffect(() => {
    let unansweredQuestion = test.testQuestions.items.find(item => !item.userAnswer && !answeredQuestions.includes(item.id));
    setCurrentQuestion(unansweredQuestion);
  }, [answeredQuestions]);

  const handleButtonClick = async () => {
    if (isCorrecting) {

      setAnsweredQuestions([...answeredQuestions, currentQuestion.id]);

      if (!test.testQuestions.items.find(item => !item.userAnswer && item.id !== currentQuestion.id && !answeredQuestions.includes(item.id))) {
        setIsLoading(true);
        addAnswerPromise.then(result => {
          const closedTest = API.graphql({
            query: closeTest,
            variables: {
              userId: userId,
              topicId: topicId,
              testId: test.id
            },
            authMode: 'API_KEY'
          });
          navigate('/theory');
        }).catch(error => {
          console.error('Error saving user answer:', error);
          console.error('Error with this input:', selectedAnswers);
        });
      }
      else {
        setSelectedAnswers([]);
        setIsCorrecting(false);
      }
    } else {

      const promise = API.graphql({
        query: addUserAnswer,
        variables: {
          testTopicId: topicId,
          questionTopicId: currentQuestion.question.topic.id,
          userId: userId,
          correctAnswers: currentQuestion.question.correctAnswers,
          questionId: currentQuestion.question.id,
          selectedAnswers: selectedAnswers,
          testQuestionId: currentQuestion.id,
          testId: test.id
        },
        authMode: 'API_KEY'
      });
      setAddAnswerPromise(promise);
      setIsCorrecting(true);
    }
  };

  if (isLoading) {
    return <Spinner />; // Return a loading spinner when loading
  }

  if (!currentQuestion || !currentQuestion.question) {
    return (<div>no question</div>); // or return a loading spinner, or some other placeholder component
  }

  return (
    <div style={{ width: '100%' }}>
      <TestHeader
        overrides={{ '$typeEntrainement': { 'children': test.id },
        TestHeader: {width:"100%", className:"test-header"} }}
        progressBar={<ProgressBar completionRatio={answeredQuestions.length / (unansweredQuestionsLength + answeredQuestions.length)} />}
      />
      <div className="test-content-container">

        <QuestionImageWrapper src={currentQuestion.question.image}></QuestionImageWrapper>

        <div className="test-answers-container">

          <TestMenuWrapper questionId={currentQuestion.question.id} userId={userId} testId={test.id}></TestMenuWrapper>
          <div style={{ fontFamily: "Futura", fontWeight: "100", fontSize: "18px", marginLeft: '16px' }}>{currentQuestion.question.description.items[0].text}</div>
          {currentQuestion.question.answers.items.map((answer) => {
            return (
              <QCMAnswerWrapper key={answer.description.items[0].text} answer={answer} selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} isCorrecting={isCorrecting} correctAnswers={currentQuestion.question.correctAnswers} />
            );
          })}
          <ButtonWithArrow
            onClick={handleButtonClick}
            overrides={{
              "ButtonWithArrow": { "width": "100%", "className": "button-with-arrow", "height": "50px" },
              "Validate Selection": { "children": isCorrecting ? 'Next Question' : 'Check Answers' }
            }}
          >
          </ButtonWithArrow>
        </div>
      </div>
    </div>
  );
}