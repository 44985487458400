import React from 'react';
import { useTranslation } from 'react-i18next';

export function LanguageSelector() {
  const { i18n } = useTranslation();
  const languages = ['en', 'fr', 'de', 'it']; // Add more languages as needed

  const style = {
    padding: '0px',
    borderRadius: '5px',
    border: '0px solid #ccc',
    backgroundColor: '#fff',
    color: '#333',
    cursor: 'pointer',
    textTransform: 'uppercase',
    outline: 'none',
    fontWeight: '100',
    fontSize: '16px',
    fontFamily: 'Futura'
  };

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <select style={style} onChange={changeLanguage} value={i18n.language}>
      {languages.map((language, index) => (
        <option key={index} value={language}>
          {language}
        </option>
      ))}
    </select>
  );
}