// CardButtonWrapper.js
import CardWithButton from '../ui-components/CardWithButtonSecondary';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { getTestUnansweredQuestionsIds } from '../graphql/customQueries';
import { onUpdateTest, onCreateTestQuestion, onUpdateTestQuestion } from '../graphql/customSubscriptions'; // Import the custom subscription
import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './styles/CardButtonWrapper.css';

const buttonTypes = {
  primary: {
    overrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-primary' },
      "Frame 4388": { className: 'card-primary-button' },
      "buttonLabel": { "children": "New" },
      "headerLabel": { "children": "General Training" }
    },
    disabledOverrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-primary disabled' },
      "Frame 4388": { className: 'card-primary-button disabled' },
      "buttonLabel": { "children": "disabled New" },
      "headerLabel": { "children": "General Training" }
    },
    topicId: '54b1fd29-62fa-4e5d-9aaf-1e03356ad21a'
  },
  secondary1: {
    overrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-secondary' },
      "Frame 4388": { className: 'card-secondary-button' },
      "buttonLabel": { "children": "Resume (upcoming)" },
      "headerLabel": { "children": "Exam (upcoming)" }
    },
    disabledOverrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-secondary disabled' },
      "Frame 4388": { className: 'card-secondary-button' },
      "buttonLabel": { "children": "Upcoming" },
      "headerLabel": { "children": "Exam" }
    },
    topicId: '234234234234234'
  },
  secondary2: {
    overrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-secondary' },
      "Frame 4388": { className: 'card-secondary-button' },
      "buttonLabel": { "children": "Review" },
      "headerLabel": { "children": "Review missed questions" }
    },
    disabledOverrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-secondary disabled' },
      "Frame 4388": { className: 'card-secondary-button' },
      "buttonLabel": { "children": "There are no questions to retake" },
      "headerLabel": { "children": "Review missed questions" }
    },
    topicId: '68c4f79d-eeed-44c7-bf1f-666f5f3fae42'
  },
  secondary3: {
    overrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-secondary' },
      "Frame 4388": { className: 'card-secondary-button' },
      "buttonLabel": { "children": "Favorites" },
      "headerLabel": { "children": "Study favorites" }
    },
    disabledOverrides: {
      CardWithButtonSecondary: { width: '100%', className: 'card-secondary disabled' },
      "Frame 4388": { className: 'card-secondary-button' },
      "buttonLabel": { "children": "You have no favorites yet" },
      "headerLabel": { "children": "Study favorites" }
    },
    topicId: '33425828-f72c-4897-a3e6-54d282e03f64'
  },
};

function CardButtonWrapper({ type }) {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTestId, setActiveTestId] = useState(null); // Add state for active test id

  const fetchData = async (userId) => {
    if(!activeTestId){ // So it only happens on first Loading
      setIsLoading(true); 
    }
    try {
      // Make a GraphQL request to fetch the user topic summary
      const result = await API.graphql({
        query: getTestUnansweredQuestionsIds,
        variables: { userTestsId: userId, testTopicId: buttonTypes[type].topicId },
        authMode: 'API_KEY'
      });
      setDisabled(false);
      if (!result.data.listTests.items.length || !result.data.listTests.items[0].testQuestions.items.length) {
        setDisabled(true);
      }
      if (result.data.listTests.items[0]) {
        setActiveTestId(result.data.listTests.items[0].id); // Set active test id
      }
    } catch (error) {
      console.error("Error fetching user topic summary", error);
    }
    
    setIsLoading(false); 
    
  };

  useEffect(() => {
    let subscription; // Declare the subscription variable
    let questionSubscription; // Declare the question subscription variable
    const userId = user.attributes.sub; // get current user id

    if (user) {
      fetchData(userId);
    }

    subscription = API.graphql({
      query: onUpdateTest,
      variables: { userId: userId, topicId: buttonTypes[type].topicId },
      authMode: 'API_KEY'
    }).subscribe({
      next: (data) => {
        fetchData(userId);
      },
      error: (error) => {
        console.error("Error with subscription", error);
      }
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [user, type]);

  useEffect(() => {
    let questionCreateSubscription;
    let questionUpdateSubscription;

    if (activeTestId) {
      questionCreateSubscription = API.graphql({
        query: onCreateTestQuestion,
        variables: { testId: activeTestId },
        authMode: 'API_KEY'
      }).subscribe({
        next: (data) => {
          console.log('subscription create for test ', activeTestId)
          fetchData(user.attributes.sub);
        },
        error: (error) => {
          console.error("Error with question subscription", error);
        }
      });
      questionUpdateSubscription = API.graphql({
        query: onUpdateTestQuestion,
        variables: { testId: activeTestId },
        authMode: 'API_KEY'
      }).subscribe({
        next: (data) => {
          console.log('subscription update for test ', activeTestId)
          fetchData(user.attributes.sub);
        },
        error: (error) => {
          console.error("Error with question subscription", error);
        }
      });
    }

    return () => {
      if (questionCreateSubscription) {
        questionCreateSubscription.unsubscribe();
      }
      if (questionUpdateSubscription) {
        questionUpdateSubscription.unsubscribe();
      }
    };
  }, [activeTestId]);

  return (
    isLoading ? (
      <div>
        <Skeleton height={'132px'}/>
       
      </div>
    ) : (
      <div>
        <CardWithButton
          onClick={disabled ? null : () => navigate(`/test?topic=${buttonTypes[type].topicId}`)} // Pass active test id in the URL only if not disabled
          overrides={disabled ? buttonTypes[type].disabledOverrides : buttonTypes[type].overrides}
        />
      </div>
    )
  );
}

export default CardButtonWrapper;