import React, { useEffect, useState } from 'react';
import QCMAnswer from '../ui-components/QCMAnswer';
import './styles/QCMAnswerWrapper.css';

function QCMAnswerWrapper({ answer, selectedAnswers, setSelectedAnswers, isCorrecting, correctAnswers }) {
  const [isSelected, setIsSelected] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswerClick = () => {
    if (!isCorrecting) {
      if (selectedAnswers && selectedAnswers.includes(answer.id)) {
        setSelectedAnswers(selectedAnswers.filter(id => id !== answer.id));
      } else {
        setSelectedAnswers([...selectedAnswers, answer.id]);
      }
      setIsSelected(!isSelected);
    }
  };

  useEffect(() => {
    setIsSelected(false);
    setIsCorrect(null);
  }, [answer]);

  useEffect(() => {
    if (isCorrecting && Array.isArray(selectedAnswers) && Array.isArray(correctAnswers)) {
      if (!selectedAnswers.includes(answer.id) && !correctAnswers.includes(answer.id)) {
        setIsCorrect(true);
      } else {
        setIsCorrect(selectedAnswers.includes(answer.id) && correctAnswers.includes(answer.id));
      }
    }
  }, [isCorrecting, selectedAnswers, correctAnswers, answer.id]);

  const checkedClass = isSelected ? 'checked' : 'unchecked';
  const correctClass = isCorrect === null ? '' : (isCorrect ? 'correct' : 'false');

  return (
    <div className={`qcm-wrapper ${checkedClass} ${correctClass}`} onClick={handleAnswerClick}>
      <QCMAnswer
        overrides={{
          "QCMAnswer": {
            "width": "100%",
            "className": `qcm-answer ${checkedClass} ${correctClass}`,
          },
          "description": {
            "className":"qcm-answer-label",
            "children": answer.description.items[0].text
          }
        }}
        checkBox={
          <div
            className={`custom-checkbox ${checkedClass}`}
          />
        }
      />
      {correctClass !== '' && (
        <div className={`correction-icon ${correctClass}`}>
          <img src={isCorrect ? "/valid.svg" : "/cross.svg"} alt={isCorrect ? "Valid Icon" : "Cross Icon"} />
        </div>
      )}
    </div>
  );
}

export default QCMAnswerWrapper;