import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

const QuestionImageWrapper = ({ src }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setIsLoading(false);
    }, [src]);

    return (
        isLoading 
            ? <Skeleton height="464.84px" width="700px"/> 
            : <img src={src} className="test-image" />
    );
};

export default QuestionImageWrapper;