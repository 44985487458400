/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function QCMAnswer(props) {
  const { checkBox, overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="332px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      borderRadius="4px"
      padding="0px 0px 0px 16px"
      {...getOverrideProps(overrides, "QCMAnswer")}
      {...rest}
    >
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children={checkBox}
        {...getOverrideProps(overrides, "checkBox")}
      ></View>
      <Text
        fontFamily="Futura"
        fontSize="16px"
        fontWeight="500"
        color="rgba(87,90,94,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Description of the answer gave"
        {...getOverrideProps(overrides, "description")}
      ></Text>
    </Flex>
  );
}
