/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex } from "@aws-amplify/ui-react";
export default function MobileNavbarOpen(props) {
  const { frame4420, overrides, ...rest } = props;
  return (
    <Flex
      gap="32px"
      direction="column"
      width="492px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="16px 32px 16px 32px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "MobileNavbarOpen")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children={frame4420}
        {...getOverrideProps(overrides, "Frame 4420")}
      ></Flex>
      <Flex
        gap="32px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 4421")}
      >
        <Button
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Sign up"
          {...getOverrideProps(overrides, "Button4105477")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          size="default"
          isDisabled={false}
          variation="link"
          children="Log in"
          {...getOverrideProps(overrides, "Button4105476")}
        ></Button>
      </Flex>
    </Flex>
  );
}
