/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        userFavoritesId
        questionFavoritesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestQuestion = /* GraphQL */ `
  query GetTestQuestion($id: ID!) {
    getTestQuestion(id: $id) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const listTestQuestions = /* GraphQL */ `
  query ListTestQuestions(
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        questionAnswersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAnswer = /* GraphQL */ `
  query GetUserAnswer($id: ID!) {
    getUserAnswer(id: $id) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const listUserAnswers = /* GraphQL */ `
  query ListUserAnswers(
    $filter: ModelUserAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserTopicSummary = /* GraphQL */ `
  query GetUserTopicSummary($id: ID!) {
    getUserTopicSummary(id: $id) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const listUserTopicSummaries = /* GraphQL */ `
  query ListUserTopicSummaries(
    $filter: ModelUserTopicSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTopicSummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        topicId
        numberOfQuestionsAnswered
        accuracy
        createdAt
        updatedAt
        userSummariesId
        userTopicSummaryTopicId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userTopicSummariesByUserIdAndTopicId = /* GraphQL */ `
  query UserTopicSummariesByUserIdAndTopicId(
    $userId: ID!
    $topicId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTopicSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTopicSummariesByUserIdAndTopicId(
      userId: $userId
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        topicId
        numberOfQuestionsAnswered
        accuracy
        createdAt
        updatedAt
        userSummariesId
        userTopicSummaryTopicId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTranslation = /* GraphQL */ `
  query GetTranslation($id: ID!) {
    getTranslation(id: $id) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const listTranslations = /* GraphQL */ `
  query ListTranslations(
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranslations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        language
        createdAt
        updatedAt
        questionDescriptionId
        questionExplanationId
        answerDescriptionId
        topicNameId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        userId
        topicId
        date
        score
        isCurrent
        isNext
        createdAt
        updatedAt
        userTestsId
        questionTestsId
        testTopicId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsByUserIdAndTopicId = /* GraphQL */ `
  query TestsByUserIdAndTopicId(
    $userId: ID!
    $topicId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByUserIdAndTopicId(
      userId: $userId
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userId
        topicId
        date
        score
        isCurrent
        isNext
        createdAt
        updatedAt
        userTestsId
        questionTestsId
        testTopicId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
