// MobileNavbar.js
import React, { useState } from 'react';
import MobileNavbarUI from '../ui-components/MobileNavbar';
import MobileNavbarOpen from '../ui-components/MobileNavbarOpen';

export function MobileNavbar({ route, signOut, navigate, languageSelector, applogo, navbarLinks }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const logOut = () => {
        signOut();
        navigate('/');
    };

    return (
        <div style={{ width: '100%' }}>
            <MobileNavbarUI
                menu={
                    <img
                        src="/menu.svg"
                        alt="Menu Icon"
                        height="100%"
                        content='center'
                        onClick={handleToggle}
                        style={{ cursor: 'pointer', alignSelf: 'center' }}
                    />
                }
                applogo={applogo}
                overrides={{

                    applogo: {
                        height: "18px",
                        width: "auto"
                    },
                    MobileNavbar: {
                        width: "100%",
                        className: "mobile-navbar"
                    }
                }}
                languageSelector={languageSelector}

            >
            </MobileNavbarUI>
            {isOpen && <MobileNavbarOpen overrides={
                {
                    MobileNavbarOpen: { 
                        className:"mobile-navbar-menu"
                    },
                    Button4105477: {
                        onClick: route === 'authenticated' ? logOut : () => window.location.href='signup',
                        children: route === 'authenticated' ? 'Logout' : 'Sign Up',
                    },
                    Button4105476: {
                        onClick: route === 'authenticated' ? () => navigate('/theory') : () => window.location.href = '/signin',
                        children: route === 'authenticated' ? 'Study' : 'Login',
                    }
                }}
                frame4420={navbarLinks} />}
        </div>
    );
}