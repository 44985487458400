export const getTestQuestionsAndAnswers = /* GraphQL */ `
  query GetTestQuestionsAndAnswers($id: ID!) {
    getTest(id: $id) {
        createdAt
        testQuestions {
          items {
            question {
                id
              image
              topicID
              answers {
                items {
                  id
                  description
                  isCorrect
                  correctionDescription
                }
              }
            }
          }
        }
    }
  }
`;

export const getUserTestInstanceWithAnswers = /* GraphQL */ `
    query getTestQuestionsAndAnswers($id: ID!) {
        getUserTestInstance(id: $id){
        testID
        userAnswers{
            items{
            questionID
            answerID
            userChoice
            isCorrect
            }
        }
        }
    }
`;


export const getTestQuestionsByTestInstance = /* GraphQL */ `
    query GetTestQuestionsByTestInstance($id: ID!) {
    getUserTestInstance(id: $id) {
        testID
            test{
              testQuestions{
                items{
                  question{
                        id
                        topicID
                        userAnswers(filter: { userTestInstanceID: { eq: $id } }){
                      items{
                                id
                                isCorrect
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export const getUserTestInstanceByID = /* GraphQL */ `
  query GetTestQuestionsAndAnswers($id: ID!) {
    getUserTestInstance(id: $id){
    testID
    userAnswers{
      items{
        questionID
        answerID
        userChoice
        isCorrect
        question {
            topicID
        }
      }
    }
  }
}
`;


export const userTopicSummariesByUserIdAndTopicId = /* GraphQL */ `
  query UserTopicSummariesByUserIdAndTopicId(
    $userId: ID!
    $topicId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTopicSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTopicSummariesByUserIdAndTopicId(
      userId: $userId
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        topicId
        topic{
          numberOfQuestions
        }
        accuracy
        createdAt
        updatedAt
        numberOfQuestionsAnswered
        userSummariesId
        userTopicSummaryTopicId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getCurrentTestByTopicAndUser = /* GraphQL */ `
query ListTests($topicId: ID!, $userId: ID!, $lang: Language!) {
  listTests(
    filter: {testTopicId: {eq: $topicId}, userTestsId: {eq: $userId}, isCurrent: {eq: true}}
  ) {
    items {
      id
      testQuestions {
        items {
          id
          question {
            image
            description(filter: {language: {eq: $lang}}) {
              items {
                text
                language
              }
            }
            explanation(filter: {language: {eq: $lang}}) {
              items {
                text
                language
              }
            }
            answers {
              items {
                id
                description(filter: {language: {eq: $lang}}) {
                  items {
                    text
                    language
                  }
                }
              }
            }
            correctAnswers
            isAsa
            id
            topic {
              id
            }
          }
          userAnswer {
            selectedAnswers
          }
        }
      }
    }
  }
}
`;

export const getTestUnansweredQuestionsIds = /* GraphQL */ `
  query GetTestQuestionsIds($userTestsId: ID!, $testTopicId: ID!) {
    listTests(filter:{userTestsId: { eq : $userTestsId} isCurrent: { eq : true} testTopicId: { eq : $testTopicId}}){
      items{
        id
        testQuestions(filter: {testQuestionUserAnswerId: {attributeExists: false}} ){
          items{
            userAnswer{
              id
            }
          }
        }
      }
    }
  }
`;
