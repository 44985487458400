import ThemeCard from '../ui-components/ThemeCard';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { userTopicSummariesByUserIdAndTopicId } from '../graphql/customQueries';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { onUpdateUserTopicSummary } from '../graphql/subscriptions';

const typeMapping = {
  signs: {
    imageSrc: '/signs.svg',
    title: 'Signs, lights & signals',
    topicId: '9fcbbab9-88fd-4aa8-be60-cc55ba808c0d'
  },
  stop: {
    imageSrc: '/stop.svg',
    title: 'Stop & parking',
    topicId: 'a1dec994-6ff3-401f-a26f-145a2013cc4d'
  },
  conduite: {
    imageSrc: '/conduite.svg',
    title: 'Driving behavior',
    topicId: '8c8816ba-d665-4406-9c8d-c20198949504'
  },
  priorite: {
    imageSrc: '/priorite.svg',
    title: 'Priority',
    topicId: '2b795f71-996a-44bd-bf30-fb751485a874'
  },
  accident: {
    imageSrc: '/accident.svg',
    title: 'Accidents & breakdowns',
    topicId: 'f9ca47fa-a490-49e8-9c42-ace407f4e8bf'
  },
  danger: {
    imageSrc: '/danger.svg',
    title: 'Danger, safety & caution',
    topicId: '0a2f25a5-3f56-4a01-be58-608c6065ede4'
  },
};

function ThemeCardWrapper({ type }) {
  const [userTopicSummary, setUserTopicSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // New state variable
  const { user } = useAuthenticator((context) => [context.user]);
  const { imageSrc, title, topicId } = typeMapping[type];
  const navigate = useNavigate();

  useEffect(() => {
    let subscription;

    const fetchData = async () => {
      setIsLoading(true); // Set loading to true at the start of the fetch
      try {
        const userId = user.attributes.sub; // get current user id
        // Make a GraphQL request to fetch the user topic summary
        const result = await API.graphql({
          query: userTopicSummariesByUserIdAndTopicId,
          variables: { userId: userId, topicId: { eq: topicId } },
          authMode: 'API_KEY'
        });

        setUserTopicSummary(result.data.userTopicSummariesByUserIdAndTopicId.items[0]);

        // Create subscription
        subscription = API.graphql({
          query: onUpdateUserTopicSummary,
          variables: { filter: { userId: { eq: userId }, topicId: { eq: topicId } } },
          authMode: 'API_KEY'
        }).subscribe({
          next: (data) => {
            setUserTopicSummary(data.value.data.onUpdateUserTopicSummary);
          },
          error: (error) => {
            console.error("Error with subscription", error);
          }
        });
      } catch (error) {
        console.error("Error fetching user topic summary", error);
      }
      setIsLoading(false); // Set loading to false after the fetch is complete
    };

    if (user) {
      fetchData();
    }

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };

  }, [user, type]);

  return (
    <div>
      {isLoading ? (
        <div>
          <Skeleton height={150} />
          <Skeleton count={3} />
        </div>
      ) : (
        <ThemeCard
          onClick={() => navigate(`/test?topic=${topicId}`)}
          overrides={{
            'ThemeCard': { className: 'theme-card' },
            'circulation 1': { src: imageSrc, objectFit: 'contain', height: '100px', padding: '32px', className: 'theme-card-image' },
            'Panneau de signalisation': { children: title}
          }}
          answered={
            <div style={{fontFamily: 'Futura', fontSize:'14px', color:'#575a5e', marginBottom:'4px'}}>{userTopicSummary.numberOfQuestionsAnswered} / {userTopicSummary.topic.numberOfQuestions} Answered questions</div>
          }
          accuracy={
            <div style={{fontFamily: 'Futura', fontSize:'14px', color:'#575a5e'}}>{userTopicSummary.accuracy} accuracy</div>
          }
        />
      )}
    </div>
  );
}

export default ThemeCardWrapper;