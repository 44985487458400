// components/Theory.js
import { useAuthenticator } from '@aws-amplify/ui-react';
import CardWithButtonPrimary from '../ui-components/CardWithButtonPrimary';
import CardWithButtonSecondary from '../ui-components/CardWithButtonSecondary';
import CardButtonWrapper from '../custom-components/CardButtonWrapper';
import ThemeCardWrapper from '../custom-components/ThemeCardWrapper';
import { useTranslation } from 'react-i18next';
import Spinner from './Spinner';
import { useEffect } from 'react';


import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export function Theory() {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.body.style.backgroundColor = '#F5F5F5';
    return () => {
      document.body.style.backgroundColor = '#fff';
    };
  }, []);

  return (
    <main>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <div className="main-container">
          <div className="inner-container">
            <div className="title-container">
              Global training
            </div>
            <div className="grid-container">
              <CardButtonWrapper type="primary" />
              <CardButtonWrapper type="secondary1" />
              <CardButtonWrapper type="secondary2" />
              <CardButtonWrapper type="secondary3" />
            </div>
            <div className="title-container">
             Theme training
            </div>
            <div className="theme-grid-container">
              <ThemeCardWrapper type='signs' />
              <ThemeCardWrapper type='stop' />
              <ThemeCardWrapper type='conduite' />
              <ThemeCardWrapper type='priorite' />
              <ThemeCardWrapper type='accident' />
              <ThemeCardWrapper type='danger' />
            </div>
          </div>
        </div>
      )}
    </main>
  );
}