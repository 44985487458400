// RequireAuth.js
import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Spinner from './components/Spinner'; // Make sure to import your Spinner component

export function RequireAuth({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (route) {
      setLoading(false);
    }
  }, [route]);

  if (loading) {
    return <Spinner />; // Show Spinner while loading
  } else if (route !== 'authenticated') {
    sessionStorage.setItem('postLoginRedirect', `${location.pathname}${location.search}${location.hash}`);
    window.location.href = '/signin';
    return null;
  }
  return children;
}