export const onUpdateTest = /* GraphQL */ `
  subscription OnUpdateTest($userId: ID!, $topicId: ID!) {
    onUpdateTest(filter: {userId: { eq : $userId}, topicId: { eq : $topicId}}) {
      id
      isCurrent
      testQuestions(filter: {testQuestionUserAnswerId: {attributeExists: true}} ){
        items{
          userAnswer{
            id
          }
        }
      }
    }
    onCreateTest(filter: {userId: { eq : $userId}, topicId: { eq : $topicId}}) {
      id
      isCurrent
      testQuestions(filter: {testQuestionUserAnswerId: {attributeExists: true}} ){
        items{
          userAnswer{
            id
          }
        }
      }
    }
  }
`;

export const onCreateTestQuestion = /* GraphQL */ `
  subscription OnCreateTestQuestion($testId: ID!){
  onCreateTestQuestion(filter: {testId: { eq : $testId}}) {
      id
      testId
      createdAt
    }
  }
`;

export const onUpdateTestQuestion = /* GraphQL */ `
  subscription OnUpdateTestQuestion($testId: ID!){
  onUpdateTestQuestion(filter: {testId: { eq : $testId}}) {
      id
      testId
      createdAt
    }
  }
`;