// Layout.js
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMediaQuery } from 'react-responsive';
import MainNavbar from '../ui-components/MainNavbar';
import { MobileNavbar } from './MobileNavbar';
import { LanguageSelector } from './LanguageSelector';
import { NavbarLinks } from './NavbarLinks';

export function Layout() {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  function logOut() {
    signOut();
    navigate('/');
  }

  return (
    <main>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        boxShadow: '0 2px 2px -2px gray',
        marginBottom: '1px',
        backgroundColor: 'white'
      }}>
        {isMobile ? (
          <MobileNavbar 
            route={route} 
            signOut={signOut} 
            navigate={navigate} 
            languageSelector={<LanguageSelector />} 
            applogo={
              <img 
                src="/swissnavigo.svg" 
                alt="Swiss Navigo Logo" 
                height="100%"
                content='center' 
                onClick={() => navigate('/')}
                style={{ cursor: 'pointer', alignSelf: 'center' }}
              />
            } 
            navbarLinks={<NavbarLinks currentRoute={route} />}
          />
        ) : (
          <MainNavbar route={route} overrides={{
            MainNavbar: { 
              width: '100%', 
              maxWidth: '1280px' 
            },
            Button39405645: {
              onClick: route === 'authenticated' ? logOut : () => window.location.href='signup',
              children: route === 'authenticated' ? 'Logout' : 'Sign Up',
              fontFamily:'Futura',
              fontWeight:'100',
              fontSize:'18px',
              letterSpacing: '0.05em'
            },
            Button38513846: {
              onClick: route === 'authenticated' ? () => navigate('/theory') : () => window.location.href = '/signin',
              children: route === 'authenticated' ? 'Study' : 'Login',
              fontFamily:'Futura',
              fontWeight:'100',
              fontSize:'18px',
              letterSpacing: '0.05em'
            },
            applogo: {
              width:"auto",
              height:"25px"
            },
            navbarLinks: {
              width:"auto"
            }
          }} 
          languageSelector={
            <LanguageSelector></LanguageSelector>
          }
          applogo={
            
            <img 
              src="/swissnavigo.svg" 
              alt="Swiss Navigo Logo" 
              height="87%"
              content='center' 
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer', alignSelf: 'center' }}
            />
            
          }
          navbarLinks={
            <NavbarLinks currentRoute={route} />
          }
          />
        )}
      </div>
      <Outlet />

    </main>
  );
}
