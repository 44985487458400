/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function HeroNavigo(props) {
  const { right, overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="row"
      width="1280px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="32px 32px 32px 32px"
      {...getOverrideProps(overrides, "HeroNavigo")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="596px"
        height="550px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="120px 100px 120px 100px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Left")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "HeroMessage")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Message")}
          >
            <Flex
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 4414")}
            >
              <Text
                fontFamily="Futura"
                fontSize="16px"
                fontWeight="500"
                color="rgba(237,23,23,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="🇨🇭"
                {...getOverrideProps(overrides, "Eyebrow38513858")}
              ></Text>
              <Text
                fontFamily="Futura"
                fontSize="16px"
                fontWeight="500"
                color="rgba(237,23,23,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Official exam questions"
                {...getOverrideProps(overrides, "Eyebrow4104467")}
              ></Text>
              <Text
                fontFamily="Futura"
                fontSize="16px"
                fontWeight="500"
                color="rgba(237,23,23,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="🇨🇭"
                {...getOverrideProps(overrides, "Eyebrow4104466")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Futura"
              fontSize="24px"
              fontWeight="500"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Ace Your Test with AI Theorie"
              {...getOverrideProps(overrides, "Heading")}
            ></Text>
            <Text
              fontFamily="Futura"
              fontSize="16px"
              fontWeight="500"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Your Complete Partner for Acing the Driving Exam with our State-of-the-Art Multi-Platform Software and AI Assistance"
              {...getOverrideProps(overrides, "Body")}
            ></Text>
          </Flex>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="J’en profite"
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="596px"
        height="550px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="10px 10px 10px 10px"
        backgroundColor="rgba(32,112,182,1)"
        children={right}
        {...getOverrideProps(overrides, "Right")}
      ></Flex>
    </Flex>
  );
}
