import { useEffect } from "react";
import { Authenticator, useAuthenticator, View, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router';
import { useMediaQuery } from 'react-responsive';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 479px)' });
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      const redirectUrl = sessionStorage.getItem('postLoginRedirect') || '/';
      sessionStorage.removeItem('postLoginRedirect');
      navigate(redirectUrl)
    }
  }, [route, navigate, from]);

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex' }}>
      <View className="auth-wrapper" style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
        <img src="/swissnavigo.svg" alt="Image" style={{ width: '100%', maxWidth: isSmallMobile ? '261px' : '400px', marginBottom: '32px', cursor:'pointer' }} onClick={() => navigate('/')}/>
        <Authenticator initialState="signIn" ></Authenticator>
      </View>
      {!isMobile && (
        <div style={{ flex: 1, backgroundColor: tokens.colors.brand.primary[60], display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/login.svg" alt="Login" height="auto" width="50%"/>
        </div>
      )}
    </div>
  );
}