import React, { useState, useEffect } from 'react';
import TestMenu from '../ui-components/TestMenu';
import Skeleton from 'react-loading-skeleton';
import FavoriteSelector from './FavoriteSelector';

const TestMenuWrapper = ({ questionId, userId, testId }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        isLoading 
            ? <Skeleton height="30px"/> 
            : 
                <TestMenu 
                        overrides={{ TestMenu: { className:"test-menu"}}}
                        favorite={ <FavoriteSelector questionId={questionId} userId={userId} testId={testId} />}
                        book={ 
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height:"100%"}}>
                                <img src="/book.svg" alt="book" height={"25px"}/> 
                            </div>
                        }
                        arrowLeft={<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height:"100%"}}>
                        <img src="/left.svg" alt="book" height={"16px"}/> 
                    </div>}
                        arrowRight={
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height:"100%"}}>
                                <img src="/right.svg" alt="book" height={"16px"}/> 
                            </div>
                        }
                        page={
                            <div style={{ fontFamily: 'Futura', fontSize: '18px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height:"100%" }}>
                                <div><u>1</u> / 25</div>
                            </div>
                        }
                    
                />
              
    );
};

export default TestMenuWrapper;
