import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import Spinner from './Spinner';
import { getCurrentTestByTopicAndUser } from '../graphql/customQueries';
import { TestInstance } from './TestInstance'

export function Test() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get('topic');
  const { user } = useAuthenticator((context) => [context.user]);
  const [testData, setTestData] = useState(null);

  useEffect(() => {
    if (topicId) {
      fetchData();
    }
  }, [topicId]);


  const fetchData = async () => {
    try {

      const userTestInstanceResult = await API.graphql({
        query: getCurrentTestByTopicAndUser,
        variables: { topicId: topicId, userId: user.username, lang: 'FR' }, // use the id from the URL
        authMode: 'API_KEY'
      });

      const test = userTestInstanceResult.data.listTests.items[0];

      setTestData(test);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="test-main">
      {testData ? (
        <div className="test-container">
          <div className="test-container">
            <div className="test-inner-container">
              <TestInstance test={testData} topicId={topicId}></TestInstance>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </main>
  );
}