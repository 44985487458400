/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFavorite = /* GraphQL */ `
  subscription OnCreateFavorite($filter: ModelSubscriptionFavoriteFilterInput) {
    onCreateFavorite(filter: $filter) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const onUpdateFavorite = /* GraphQL */ `
  subscription OnUpdateFavorite($filter: ModelSubscriptionFavoriteFilterInput) {
    onUpdateFavorite(filter: $filter) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const onDeleteFavorite = /* GraphQL */ `
  subscription OnDeleteFavorite($filter: ModelSubscriptionFavoriteFilterInput) {
    onDeleteFavorite(filter: $filter) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onCreateQuestion(filter: $filter) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onUpdateQuestion(filter: $filter) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onDeleteQuestion(filter: $filter) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const onCreateTestQuestion = /* GraphQL */ `
  subscription OnCreateTestQuestion(
    $filter: ModelSubscriptionTestQuestionFilterInput
  ) {
    onCreateTestQuestion(filter: $filter) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const onUpdateTestQuestion = /* GraphQL */ `
  subscription OnUpdateTestQuestion(
    $filter: ModelSubscriptionTestQuestionFilterInput
  ) {
    onUpdateTestQuestion(filter: $filter) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const onDeleteTestQuestion = /* GraphQL */ `
  subscription OnDeleteTestQuestion(
    $filter: ModelSubscriptionTestQuestionFilterInput
  ) {
    onDeleteTestQuestion(filter: $filter) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const onCreateAnswer = /* GraphQL */ `
  subscription OnCreateAnswer($filter: ModelSubscriptionAnswerFilterInput) {
    onCreateAnswer(filter: $filter) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const onUpdateAnswer = /* GraphQL */ `
  subscription OnUpdateAnswer($filter: ModelSubscriptionAnswerFilterInput) {
    onUpdateAnswer(filter: $filter) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const onDeleteAnswer = /* GraphQL */ `
  subscription OnDeleteAnswer($filter: ModelSubscriptionAnswerFilterInput) {
    onDeleteAnswer(filter: $filter) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const onCreateUserAnswer = /* GraphQL */ `
  subscription OnCreateUserAnswer(
    $filter: ModelSubscriptionUserAnswerFilterInput
  ) {
    onCreateUserAnswer(filter: $filter) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const onUpdateUserAnswer = /* GraphQL */ `
  subscription OnUpdateUserAnswer(
    $filter: ModelSubscriptionUserAnswerFilterInput
  ) {
    onUpdateUserAnswer(filter: $filter) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const onDeleteUserAnswer = /* GraphQL */ `
  subscription OnDeleteUserAnswer(
    $filter: ModelSubscriptionUserAnswerFilterInput
  ) {
    onDeleteUserAnswer(filter: $filter) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic($filter: ModelSubscriptionTopicFilterInput) {
    onCreateTopic(filter: $filter) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic($filter: ModelSubscriptionTopicFilterInput) {
    onUpdateTopic(filter: $filter) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic($filter: ModelSubscriptionTopicFilterInput) {
    onDeleteTopic(filter: $filter) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserTopicSummary = /* GraphQL */ `
  subscription OnCreateUserTopicSummary(
    $filter: ModelSubscriptionUserTopicSummaryFilterInput
  ) {
    onCreateUserTopicSummary(filter: $filter) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const onUpdateUserTopicSummary = /* GraphQL */ `
  subscription OnUpdateUserTopicSummary(
    $filter: ModelSubscriptionUserTopicSummaryFilterInput
  ) {
    onUpdateUserTopicSummary(filter: $filter) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const onDeleteUserTopicSummary = /* GraphQL */ `
  subscription OnDeleteUserTopicSummary(
    $filter: ModelSubscriptionUserTopicSummaryFilterInput
  ) {
    onDeleteUserTopicSummary(filter: $filter) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const onCreateTranslation = /* GraphQL */ `
  subscription OnCreateTranslation(
    $filter: ModelSubscriptionTranslationFilterInput
  ) {
    onCreateTranslation(filter: $filter) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const onUpdateTranslation = /* GraphQL */ `
  subscription OnUpdateTranslation(
    $filter: ModelSubscriptionTranslationFilterInput
  ) {
    onUpdateTranslation(filter: $filter) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const onDeleteTranslation = /* GraphQL */ `
  subscription OnDeleteTranslation(
    $filter: ModelSubscriptionTranslationFilterInput
  ) {
    onDeleteTranslation(filter: $filter) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const onCreateTest = /* GraphQL */ `
  subscription OnCreateTest($filter: ModelSubscriptionTestFilterInput) {
    onCreateTest(filter: $filter) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
export const onUpdateTest = /* GraphQL */ `
  subscription OnUpdateTest($filter: ModelSubscriptionTestFilterInput) {
    onUpdateTest(filter: $filter) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
export const onDeleteTest = /* GraphQL */ `
  subscription OnDeleteTest($filter: ModelSubscriptionTestFilterInput) {
    onDeleteTest(filter: $filter) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
