import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { updateUserFavoriteTest, createFavorite, deleteFavorite } from '../graphql/mutations';
import { listFavorites } from '../graphql/queries';

function FavoriteSelector({ questionId, userId, testId }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null); // Initialize favoriteId as null
  const [isLoading, setIsLoading] = useState(false); // Initialize isLoading as false

  useEffect(() => {
    // Fetch favorite data to check if the user has already favorited the question
    const fetchFavoriteData = async () => {
      setIsLoading(true); // Set loading state to true before API call
      try {
        const favoriteData = await API.graphql({
          query: listFavorites,
          variables: { filter: { userFavoritesId: { eq: userId}, questionFavoritesId: {eq: questionId} } },
          authMode: 'API_KEY'
        });
        const fetchedFavoriteId = favoriteData.data.listFavorites.items.length > 0 ? favoriteData.data.listFavorites.items[0].id : null; // Check if listFavorites has items, if not assign null
        setFavoriteId(fetchedFavoriteId); // Update favoriteId state
        setIsFavorite(fetchedFavoriteId !== null); // Set isFavorite to true if favoriteId is not null
      } catch (error) {
        console.error("Error fetching favorite data", error);
      } finally {
        setIsLoading(false); // Set loading state to false after API call
      }
    };

    fetchFavoriteData();
  }, [userId, questionId]);

  const toggleFavorite = async () => {
    if (isLoading) return; // Return early if API is still loading
    setIsLoading(true); // Set loading state to true before API call
    setIsFavorite(!isFavorite);
    try {
      if (isFavorite) {
        // If the question is already favorited, remove it from favorites
        await API.graphql({
          query: deleteFavorite,
          variables: { input: { id: favoriteId } },
          authMode: 'API_KEY'
        });
        setFavoriteId(null); // Reset favoriteId to null after deletion
      } else {
        // If the question is not favorited, add it to favorites
        console.log('Question id' , JSON.stringify(questionId));
        const response = await API.graphql({
          query: createFavorite,
          variables: { input: { userFavoritesId: userId, questionFavoritesId: questionId } },
          authMode: 'API_KEY'
        });
        setFavoriteId(response.data.createFavorite.id); // Update favoriteId with the id of the newly created favorite
      }

      // Update the user's favorite test
      await API.graphql({
        query: updateUserFavoriteTest,
        variables: { userId, questionId, testId, isFavorite: !isFavorite },
        authMode: 'API_KEY'
      });

    } catch (error) {
      setIsFavorite(!isFavorite);
      console.error("Error updating favorite", error);
    } finally {
      setIsLoading(false); // Set loading state to false after API call
    }
  };

  return (
    <div onClick={toggleFavorite} style={{fontSize: '1.5em', cursor:'pointer', userSelect: 'none', marginLeft:'1px'}}>
      {isFavorite ? <span style={{color: 'white'}}>&#9733;</span> : <span style={{color: 'white'}}>&#9734;</span>} 
    </div>
  );
}

export default FavoriteSelector;
