//App.js
import { Authenticator } from '@aws-amplify/ui-react';

import { Theory } from './components/Theory';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { SignUp } from './components/SignUp';
import { Test } from './components/Test';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import Spinner from './components/Spinner';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { studioTheme } from './ui-components'
// import { History } from './components/History'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import "react-loading-skeleton/dist/skeleton.css";
import { Suspense } from 'react';
function MyRoutes() {
  return (
    <Suspense fallback={<Spinner/>}>
    <ThemeProvider theme={studioTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/theory"
            element={
              <RequireAuth>
                <Theory />
              </RequireAuth>
            }
          />
          <Route
            path="/test"
            element={
              <RequireAuth>
                <Test />
              </RequireAuth>
            }
          />
          <Route path="/signin/*" element={<Login />} />
          <Route path="/signup/*" element={<SignUp />} />
           {/*<Route path="/history/*" element={<RequireAuth><History></History></RequireAuth>}></Route>*/}
        </Route>
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
    </Suspense>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;