// ProgressBar.jsx
import React from 'react';

const ProgressBar = ({ completionRatio }) => {

  return (
    <div className={"progress-bar"}>
      <div className={"progress"} style={{width: `${completionRatio * 100}%`}}></div>
    </div>
  );
};

export default ProgressBar;