// components/NavbarLinks.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMediaQuery } from 'react-responsive';

export function NavbarLinks({ currentRoute }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const links = [
    { name: 'The app', route: '/' },
    ...(route === 'authenticated' ? [{ name: 'Theory', route: '/theory' }] : []),
    /*
    ...(route === 'authenticated' ? [{ name: 'Train', route: '/train' }] : []),
    ...(route === 'authenticated' ? [{ name: 'Test', route: '/test' }] : []),
    
    ...(route === 'authenticated' ? [{ name: 'My performances', route: '/history' }] : []),
    */
    // Add more links here as needed
  ];

  return (
    <div className={`navbar-links ${isMobile ? 'mobile' : ''}`}>
      {links.map((link, index) => (
        <a 
          key={index}
          onClick={() => navigate(link.route)}
          className={`navbar-link ${location.pathname === link.route ? 'active' : ''}`}
        >
          {link.name}
        </a>
      ))}
    </div>
  );
}