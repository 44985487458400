/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function TestHeader(props) {
  const { progressBar, overrides, ...rest } = props;
  return (
    <Flex
      gap="32px"
      direction="row"
      width="1280px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="64px 32px 0px 32px"
      {...getOverrideProps(overrides, "TestHeader")}
      {...rest}
    >
      <View
        width="72px"
        height="29px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "TestType")}
      >
        <Text
          fontFamily="Futura"
          fontSize="22px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="26.400001525878906px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="EXAM"
          {...getOverrideProps(overrides, "EXAM")}
        ></Text>
      </View>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="6px"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children={progressBar}
        {...getOverrideProps(overrides, "progressBar")}
      ></Flex>
    </Flex>
  );
}
