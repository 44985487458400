/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, View } from "@aws-amplify/ui-react";
export default function TestMenu(props) {
  const { favorite, book, arrowLeft, page, arrowRight, overrides, ...rest } =
    props;
  return (
    <Flex
      gap="24px"
      direction="row"
      width="480px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
      borderRadius="4px"
      padding="8px 16px 8px 16px"
      backgroundColor="rgba(0,0,0,1)"
      {...getOverrideProps(overrides, "TestMenu")}
      {...rest}
    >
      <View
        width="34px"
        height="34px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children={favorite}
        {...getOverrideProps(overrides, "favorite")}
      ></View>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "pagination")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          border="0px SOLID rgba(255,255,255,1)"
          borderRadius="4px"
          padding="4px 8px 4px 8px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Frame 4413")}
        >
          <View
            width="26px"
            height="26px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            children={arrowLeft}
            {...getOverrideProps(overrides, "arrowLeft")}
          ></View>
          <View
            width="54px"
            height="22px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            children={page}
            {...getOverrideProps(overrides, "page")}
          ></View>
          <View
            width="26px"
            height="26px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            children={arrowRight}
            {...getOverrideProps(overrides, "arrowRight")}
          ></View>
        </Flex>
      </Flex>
      <View
        width="34px"
        height="34px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children={book}
        {...getOverrideProps(overrides, "book")}
      ></View>
    </Flex>
  );
}
