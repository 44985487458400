/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      learningPathIndex
      favorites {
        nextToken
        __typename
      }
      summaries {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      userFavoritesId
      questionFavoritesId
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      image
      description {
        nextToken
        __typename
      }
      explanation {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      correctAnswers
      isAsa
      favorites {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      topicQuestionsId
      __typename
    }
  }
`;
export const createTestQuestion = /* GraphQL */ `
  mutation CreateTestQuestion(
    $input: CreateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    createTestQuestion(input: $input, condition: $condition) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const updateTestQuestion = /* GraphQL */ `
  mutation UpdateTestQuestion(
    $input: UpdateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    updateTestQuestion(input: $input, condition: $condition) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const deleteTestQuestion = /* GraphQL */ `
  mutation DeleteTestQuestion(
    $input: DeleteTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    deleteTestQuestion(input: $input, condition: $condition) {
      id
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      userAnswer {
        id
        selectedAnswers
        answeredAt
        createdAt
        updatedAt
        userTopicSummaryUserAnswersId
        userAnswerTestQuestionId
        __typename
      }
      testId
      createdAt
      updatedAt
      testTestQuestionsId
      testQuestionQuestionId
      testQuestionUserAnswerId
      __typename
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      description {
        nextToken
        __typename
      }
      question {
        id
        image
        correctAnswers
        isAsa
        createdAt
        updatedAt
        topicQuestionsId
        __typename
      }
      createdAt
      updatedAt
      questionAnswersId
      __typename
    }
  }
`;
export const createUserAnswer = /* GraphQL */ `
  mutation CreateUserAnswer(
    $input: CreateUserAnswerInput!
    $condition: ModelUserAnswerConditionInput
  ) {
    createUserAnswer(input: $input, condition: $condition) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const updateUserAnswer = /* GraphQL */ `
  mutation UpdateUserAnswer(
    $input: UpdateUserAnswerInput!
    $condition: ModelUserAnswerConditionInput
  ) {
    updateUserAnswer(input: $input, condition: $condition) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const deleteUserAnswer = /* GraphQL */ `
  mutation DeleteUserAnswer(
    $input: DeleteUserAnswerInput!
    $condition: ModelUserAnswerConditionInput
  ) {
    deleteUserAnswer(input: $input, condition: $condition) {
      id
      testQuestion {
        id
        testId
        createdAt
        updatedAt
        testTestQuestionsId
        testQuestionQuestionId
        testQuestionUserAnswerId
        __typename
      }
      selectedAnswers
      answeredAt
      createdAt
      updatedAt
      userTopicSummaryUserAnswersId
      userAnswerTestQuestionId
      __typename
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      image
      name {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      numberOfQuestions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserTopicSummary = /* GraphQL */ `
  mutation CreateUserTopicSummary(
    $input: CreateUserTopicSummaryInput!
    $condition: ModelUserTopicSummaryConditionInput
  ) {
    createUserTopicSummary(input: $input, condition: $condition) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const updateUserTopicSummary = /* GraphQL */ `
  mutation UpdateUserTopicSummary(
    $input: UpdateUserTopicSummaryInput!
    $condition: ModelUserTopicSummaryConditionInput
  ) {
    updateUserTopicSummary(input: $input, condition: $condition) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const deleteUserTopicSummary = /* GraphQL */ `
  mutation DeleteUserTopicSummary(
    $input: DeleteUserTopicSummaryInput!
    $condition: ModelUserTopicSummaryConditionInput
  ) {
    deleteUserTopicSummary(input: $input, condition: $condition) {
      id
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      numberOfQuestionsAnswered
      userAnswers {
        nextToken
        __typename
      }
      accuracy
      createdAt
      updatedAt
      userSummariesId
      userTopicSummaryTopicId
      __typename
    }
  }
`;
export const createTranslation = /* GraphQL */ `
  mutation CreateTranslation(
    $input: CreateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    createTranslation(input: $input, condition: $condition) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const updateTranslation = /* GraphQL */ `
  mutation UpdateTranslation(
    $input: UpdateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    updateTranslation(input: $input, condition: $condition) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const deleteTranslation = /* GraphQL */ `
  mutation DeleteTranslation(
    $input: DeleteTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    deleteTranslation(input: $input, condition: $condition) {
      id
      text
      language
      createdAt
      updatedAt
      questionDescriptionId
      questionExplanationId
      answerDescriptionId
      topicNameId
      __typename
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      type
      user {
        id
        learningPathIndex
        createdAt
        updatedAt
        __typename
      }
      userId
      topic {
        id
        image
        numberOfQuestions
        createdAt
        updatedAt
        __typename
      }
      topicId
      testQuestions {
        nextToken
        __typename
      }
      date
      score
      isCurrent
      isNext
      createdAt
      updatedAt
      userTestsId
      questionTestsId
      testTopicId
      __typename
    }
  }
`;
export const addUserAnswer = /* GraphQL */ `
  mutation AddUserAnswer(
    $testTopicId: ID
    $questionTopicId: ID
    $userId: ID
    $correctAnswers: [ID]
    $questionId: ID
    $selectedAnswers: [ID]
    $testQuestionId: ID
    $testId: ID
  ) {
    addUserAnswer(
      testTopicId: $testTopicId
      questionTopicId: $questionTopicId
      userId: $userId
      correctAnswers: $correctAnswers
      questionId: $questionId
      selectedAnswers: $selectedAnswers
      testQuestionId: $testQuestionId
      testId: $testId
    )
  }
`;
export const closeTest = /* GraphQL */ `
  mutation CloseTest($userId: ID, $topicId: ID, $testId: ID) {
    closeTest(userId: $userId, topicId: $topicId, testId: $testId)
  }
`;
export const updateUserFavoriteTest = /* GraphQL */ `
  mutation UpdateUserFavoriteTest(
    $userId: ID
    $questionId: ID
    $testId: ID
    $isFavorite: Boolean
  ) {
    updateUserFavoriteTest(
      userId: $userId
      questionId: $questionId
      testId: $testId
      isFavorite: $isFavorite
    )
  }
`;
