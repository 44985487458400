// components/Home.js
import { Heading, ThemeProvider } from '@aws-amplify/ui-react';
import { Hero2x2, studioTheme } from '../ui-components'
import { Authenticator } from '@aws-amplify/ui-react';
import HeroNavigo from '../ui-components/HeroNavigo'
import MainNavbar from '../ui-components/MainNavbar'

import { useNavigate } from 'react-router-dom';

export function Home() {

  const navigate = useNavigate();

  return (

    <main>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',

      }}>
        <HeroNavigo overrides={{

          "Rectangle 2": { border: "2px SOLID #fff" },
          Button: {
            onClick: () => navigate('/theory'),

          },
          Left: {className: "hero-left"},
          Right: { backgroundColor: "white", className: "hero-right" },
          HeroNavigo: { className: "hero-navigo" }

        }}
          right={
            <img src='/heroright.svg' alt="Hero Right" className='hero-right' />
          }
        >
        </HeroNavigo>
      </div>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: '#fafafa'
      }}>
        <Hero2x2
          overrides={{
            line1: {
              className: "hero-line"
            },
            line2: {
              className: "hero-line"
            },
            Hero2x2:{className:"hero-2x2"}
          }}
        ></Hero2x2>
      </div>
    </main>

  );
}